/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { toast } from "react-toastify"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"

import { Content, Popup } from "page_components/customer_panel"

import CustomerProvider from "context/customer"
import { isUserLoggedIn } from "utils/functions"

const Account = () => {
  const title = "Panel klienta"
  const [popup, setPopup] = useState(false)

  const accountData = {
    popup,
    setPopup,
  }

  const auth = isUserLoggedIn()
  const isBrowser = typeof window !== "undefined"

  useEffect(() => {
    if (!auth && isBrowser) {
      toast.warning("Użytkownik nie zalogowany.")
    }
  }, [auth])

  if (!auth && isBrowser) {
    navigate("/logowanie/")
    return null
  }

  return (
    <Layout
      seo={{
        title: title,
      }}
      isOrder
    >
      <CustomerProvider>
        <Breadcrumbs title={title} />
        <Content {...accountData} />
        {popup && <Popup {...accountData} />}
      </CustomerProvider>
    </Layout>
  )
}

export default Account
